import { periodPriceText, weeklyPriceRangeText } from '~/helpers/priceHelper'
import type { Locale, SupportedCurrencies } from 'lc-services/types'
import type { InquiryRequestInput, SelectedPeriod } from '~/types/inquiry/types'

type Step = 0 | 1 | 2
type HouseBooking = {
  bookedPeriods: { start: string; end: string }[]
  currentStep: Step
  endDate: string | null
  price: {
    definitivePrice: boolean
    isValid: boolean
    text: string
    value: number | null
  }
  requestUuid: string | null
  startDate: string | null
  submitted: boolean
}

const DEFAULT_STATE_HOUSE_BOOKING: HouseBooking = {
  bookedPeriods: [],
  currentStep: 0,
  endDate: null,
  price: {
    definitivePrice: false,
    isValid: false,
    text: '',
    value: null,
  },
  requestUuid: null,
  startDate: null,
  submitted: false,
}

export const useHouseInquiryBooking = (specificId = '') => {
  const { locale, t } = useI18n<unknown, Locale>()
  const { isMobile } = useBreakpoint()
  const route = useRoute()
  const {
    $api,
    $lcServicesSearch: { searchController, searchPresenter },
  } = useNuxtApp()
  const { authenticated, userIsPartner } = useAuth()
  const { user, modifyUserInformations } = useUser()

  const houseBookingState = useState<HouseBooking>(
    'state-house-booking'.concat(specificId ? `-${specificId}` : ''),
    () => ({
      ...DEFAULT_STATE_HOUSE_BOOKING,
    }),
  )

  const booking = computed(() => houseBookingState.value)
  const bookingPeriod = computed<[string, string] | null>(() =>
    booking.value.startDate && booking.value.endDate
      ? [booking.value.startDate, booking.value.endDate]
      : null,
  )
  const bookedDates = computed(() =>
    houseBookingState.value.bookedPeriods
      .filter(({ end }) => $dayjs().isBefore($dayjs(end, 'YYYY-MM-DD')))
      .reduce<string[][]>((acc, range) => {
        const start = $dayjs(range.start, 'YYYY-MM-DD')
        const end = $dayjs(range.end, 'YYYY-MM-DD')
        const flatAcc = acc.flat()
        const diff = [
          start.format('YYYY-MM-DD'),
          ...new Array(end.diff(start, 'days')),
        ]
          .map((_, i) => start.add(i, 'days').format('YYYY-MM-DD'))
          .filter((d) => !flatAcc.includes(d))
        return diff.length ? acc.concat([diff]) : acc
      }, []),
  )

  // Remove the first/last day of every booking as checkout happens at 10AM,
  // So checkin at 5PM on the same day is still possible, and vice-versa
  // This is already done is vue-calendar, so we need a new computed for this...
  const bookedDatesExceptChecks = computed(() =>
    (bookedDates.value ?? []).map((period) => period.slice(1, -1)).flat(),
  )

  const fetchAndsetSelectedPeriod = async ({
    currency,
    houseId,
    displayAvailabilities,
    displayPrices,
    minPrice,
    maxPrice,
  }: {
    currency: SupportedCurrencies
    houseId: number
    displayAvailabilities: boolean
    displayPrices: boolean
    minPrice: {
      CHF: number
      EUR: number
      GBP: number
      USD: number
    }
    maxPrice: {
      CHF: number
      EUR: number
      GBP: number
      USD: number
    }
  }) => {
    let selectedPeriod = {} as SelectedPeriod
    const defaultPrice = weeklyPriceRangeText(
      { t, locale },
      minPrice[currency],
      maxPrice[currency],
      currency,
      displayPrices,
    )

    if (booking.value.endDate) {
      const periodParams = {
        currency,
        end_at: booking.value.endDate,
        start_at: booking.value.startDate,
        private_token: route?.query['private-token'] || '',
      }

      try {
        const res = await $api.v1.housePeriods.read(houseId, {
          params: periodParams,
        })
        const { data } = res

        let validPrice = data.allMinimumDurationValid && data.fullyCovered
        if (!displayAvailabilities) {
          validPrice = true
        }

        selectedPeriod = { ...data, validPrice }

        let isValidPeriod =
          selectedPeriod?.fullyCovered &&
          selectedPeriod?.allMinimumDurationValid
        if (!displayAvailabilities) {
          isValidPeriod = true
        }

        if (isValidPeriod) {
          const price = periodPriceText(
            { t, locale },
            selectedPeriod,
            displayPrices,
            userIsPartner.value,
          )

          const definitivePrice = selectedPeriod.periods.every(
            (period) => period.definitivePrice,
          )

          setPrice({
            definitivePrice,
            isValid: selectedPeriod.validPrice,
            price: price.value,
            text: price.text,
          })
        } else {
          setPrice({
            definitivePrice: false,
            isValid: false,
            price: minPrice[currency],
            text: defaultPrice,
          })
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      setPrice({
        definitivePrice: false,
        isValid: false,
        price: null,
        text: '',
      })
    }

    return selectedPeriod
  }

  const fetchBookings = async (
    houseId: string | number,
    params: {
      page?: number
      private_token?: string
      start_date?: Date | string | null
      end_date?: Date | string | null
    } = {},
  ) => {
    const start_date = $dayjs(
      params.start_date ?? booking.value.startDate ?? undefined,
    )
      .startOf('month')
      .format('YYYY-MM-DD')
    const end_date = $dayjs(
      params.end_date ??
        $dayjs(booking.value.endDate ?? start_date).add(
          isMobile.value ? 7 : 2,
          'month',
        ),
    )
      .endOf('month')
      .format('YYYY-MM-DD')

    const alreadyBooked = bookedDates.value.flat()
    if ([start_date, end_date].every((d) => alreadyBooked.includes(d))) return

    const fetchPaginatedBookings = async (
      page: number,
      previous: { start: string; end: string }[] = [],
    ): Promise<typeof previous> => {
      const { data = [], meta } = await $api.v1.housesBookings.read(houseId, {
        ...params,
        end_date,
        page,
        start_date,
      })
      const results = [
        ...previous,
        ...(data ?? []).map(({ checkInDate: start, checkOutDate: end }) => ({
          start,
          end,
        })),
      ]
      // Avoid "page: all" if is a next-page, re-fetch data
      return meta.next_page !== null && meta.next_page !== meta.current_page
        ? fetchPaginatedBookings(page + 1, results)
        : results
    }
    const bookedPeriods = await fetchPaginatedBookings(params.page ?? 1).catch(
      () => [] as Awaited<ReturnType<typeof fetchPaginatedBookings>>,
    )
    houseBookingState.value = {
      ...houseBookingState.value,
      bookedPeriods: houseBookingState.value.bookedPeriods
        .concat(bookedPeriods)
        .sort(),
    }
  }

  const setPrice = ({
    text,
    price,
    isValid,
    definitivePrice,
  }: {
    text: string
    price: number | null
    isValid: boolean
    definitivePrice: boolean
  }) => {
    let textFormatted = ''

    if (isValid) {
      const startDateFormat = locale.value === 'en' ? 'DD MMM' : 'DD/MM'
      const endDateFormat = locale.value === 'en' ? 'DD MMM YYYY' : 'DD/MM/YYYY'

      const formattedStart = $dayjs(
        booking.value.startDate,
        'YYYY-MM-DD',
      ).format(startDateFormat)
      const formattedEnd = $dayjs(booking.value.endDate, 'YYYY-MM-DD').format(
        endDateFormat,
      )

      textFormatted = t('house.price_date_range', {
        price: text,
        start: formattedStart,
        end: formattedEnd,
      })

      if (userIsPartner.value) {
        textFormatted = `${t('search.from')} ${textFormatted}`
      }
    } else {
      price = 0
    }

    houseBookingState.value.price = {
      text: textFormatted,
      value: price,
      isValid,
      definitivePrice,
    }
  }

  const createRequest = async (payload: {
    currency: SupportedCurrencies
    newsletter?: boolean
    request: InquiryRequestInput
    private_token?: string
    hubspotutk?: string
  }) => {
    const res = await $api.v1.requests.create(payload)
    setRequestUuid(res.data.uuid)

    if (authenticated.value && !user.value.phone) {
      await modifyUserInformations({
        user: { phone: payload.request.phone },
      }).catch(console.warn)
    }
    setNextStep()
    setSubmitted(true)
  }

  const updateRequest = async (
    request: Pick<
      InquiryRequestInput,
      | 'client_comment'
      | 'number_of_adults'
      | 'number_of_babies'
      | 'number_of_children'
    >,
  ) => {
    if (!booking.value.requestUuid) return
    await searchController.updateInquiry({
      request_uuid: booking.value.requestUuid,
      request: {
        client_comment: request.client_comment,
        number_of_adults: request.number_of_adults,
        number_of_babies: request.number_of_babies,
        number_of_children: request.number_of_children,
      },
    })
    setNextStep()
    return searchPresenter.vm.updatedInquiry
  }

  const setDates = (startDate: string | null, endDate: string | null) => {
    houseBookingState.value.endDate = endDate
    houseBookingState.value.startDate = startDate
  }

  const setStep = (step: Step) => {
    houseBookingState.value.currentStep = step
  }

  const setPrevStep = () => {
    if (houseBookingState.value.currentStep > 0) {
      houseBookingState.value.currentStep--
    }
  }

  const setNextStep = () => {
    houseBookingState.value.currentStep++
  }

  const setRequestUuid = (uuid: string) => {
    houseBookingState.value.requestUuid = uuid
  }

  const setSubmitted = (value: boolean) => {
    houseBookingState.value.submitted = value
  }

  const unsetBooking = () => {
    houseBookingState.value = {
      ...DEFAULT_STATE_HOUSE_BOOKING,
    }
  }

  return {
    booking,
    bookingPeriod,
    bookedDates,
    bookedDatesExceptChecks,
    createRequest,
    fetchAndsetSelectedPeriod,
    fetchBookings,
    setDates,
    setNextStep,
    setPrevStep,
    setPrice,
    setRequestUuid,
    setStep,
    setSubmitted,
    unsetBooking,
    updateRequest,
  }
}
