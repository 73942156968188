<script setup lang="ts">
import { VirtualBaseCarousel } from '#components'
import type { ComponentExposed } from 'vue-component-type-helpers'
import type { HouseDetails, HousePhotoAttributes } from 'lc-services/types'
import type { StateSearchHousePreviousPage } from '~/types/search/types'

const {
  house,
  housePhotos,
  matterportVideoUrl = '',
} = defineProps<{
  house: HouseDetails
  housePhotos: HousePhotoAttributes[]
  matterportVideoUrl?: string
  totalPhotos: number
}>()
const emits = defineEmits<{
  'on-slide-change': []
  'open-modal-virtual-visit': []
}>()

const { query } = useRoute()
const router = useRouter()
const { locale } = useI18n()
const localePath = useLocalePath()
const { isDesktop, isMobile } = useBreakpoint()
const { trackEvent } = useTracking()
const stateHousePreviousPage = useState<StateSearchHousePreviousPage>(
  'house-previous-page',
)

const houseCarousel = ref<ComponentExposed<
  typeof VirtualBaseCarousel<
    (typeof slides)['value'] extends Array<infer R> ? R : never
  >
> | null>(null)

const swiperOptions = {
  keyboard: true,
  loop: false,
}

const slides = computed(() => housePhotos.map((photo) => photo.url))

const openModalVirtualVisit = () => {
  emits('open-modal-virtual-visit')

  trackEvent({
    category: 'Product page',
    event: 'virtual_visit_requested',
    house_id: Number(house.id),
  })
}

const openFullscreen = () => {
  const housePhotosPath = localePath({
    name: 'house-photos',
    params: { slug: house.slug[locale.value] },
    query,
  })

  trackEvent({
    category: 'Product page',
    event: 'photo_grid_viewed',
    source: 'product page',
    house_id: Number(house.id),
  })

  router.push(housePhotosPath)
}
</script>

<template>
  <div
    class="house-heading relative overflow-hidden"
    data-testid="open-fullscreen"
    @click="openFullscreen"
  >
    <VirtualBaseCarousel
      v-if="isMobile"
      ref="houseCarousel"
      class="h-full"
      class-name="house-carousel__slider h-full"
      :base-image-style-object="{ 'object-fit': 'cover' }"
      :image-attr="{ alt: house.name }"
      :slides="slides"
      :swiper-options="swiperOptions"
      @click-on-image="openFullscreen"
      @slide-change-active-index="$emit('on-slide-change')"
    />

    <div
      v-else
      class="overlay-image scale-1 h-full cursor-pointer transition duration-500 ease-in-out md:hover:scale-[1.015]"
    >
      <BaseNuxtImg :alt="house.name" :src="slides[0]" aspect-ratio="16/9" />
    </div>

    <div
      :class="[
        'lc-container absolute bottom-0 right-0 z-30 mx-auto w-auto pb-5',
        { 'right-0': isMobile },
        { 'left-0': isDesktop },
      ]"
    >
      <span v-if="isMobile" class="house-photos-badge">
        {{ (houseCarousel?.slideIndex ?? 0) + 1 }}/{{ totalPhotos }}
      </span>
      <div v-else class="flex">
        <BaseButton
          class="button-heading"
          color="white"
          has-icon
          @click.stop="openFullscreen"
        >
          {{ $t('house.seePhotos', { number: totalPhotos }) }}
        </BaseButton>

        <BaseButton
          v-if="matterportVideoUrl"
          class="button-heading ml-4"
          color="white"
          has-icon
          @click.stop="openModalVirtualVisit"
        >
          {{ $t('house.virtualVisit.title') }}
        </BaseButton>
      </div>
    </div>

    <NuxtLink
      :aria-label="$t('global.back')"
      :to="localePath(stateHousePreviousPage)"
      class="absolute left-0 top-16 z-base p-4 lg:hidden"
      data-testid="back-button"
      type="button"
      @click.stop
    >
      <span
        class="flex size-8 items-center justify-center rounded-full bg-gray-700/20 text-white shadow backdrop-blur"
      >
        <BaseIcon class="mr-1" name="navArrowLeft" />
      </span>
    </NuxtLink>
  </div>
</template>

<style scoped>
.house-heading {
  height: 340px;
}
@screen md {
  .house-heading {
    height: 480px;
  }
}
@screen lg {
  .house-heading {
    height: 70vh;
  }
}
:deep(.no-scroll-wrap) {
  @apply overflow-y-hidden;
}
.button-heading {
  @apply font-medium text-md bg-gray-700/80 px-4 py-3.5 rounded hover:bg-secondary-400;
}

.house-photos-badge {
  @apply font-medium text-md text-white bg-gray-700/50 px-2 py-1 rounded;
}

:deep(.house-carousel__slider .swiper) {
  @apply h-full;
}
:deep(.house-carousel__slider .swiper-slide) {
  @apply before:content-[''] before:z-10 before:w-full before:h-full before:block before:absolute before:bg-gradient-to-b before:from-[#202020]/30 before:via-transparent before:to-[#868686]/0 before:to-100% before:via-40%;
}
:deep(.house-carousel__slider .swiper img) {
  @apply h-full w-full;
}

.overlay-image {
  @apply before:content-[''] before:z-10 before:w-full before:h-full before:block before:absolute before:bg-gradient-to-b before:from-[#202020]/30 before:via-transparent before:to-[#868686]/0 before:to-100% before:via-40%;
}
</style>
